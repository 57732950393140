#Marie-Cecile-4 {
  display: block;
  height: 300px;
}

#grid_1 {
  display: grid;
  grid-template-columns: 270px 280px;
  grid-gap: 10px;
  grid-auto-rows: minmax(240px, auto);
  width: fit-content;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.grid_desc_1 {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  margin-left: 5px;
}

.grid_desc_1 > h3 {
  font-size: 1.4em;
  font-weight: 500;
}

.grid_desc_1 > h4 {
  font-style: italic;
  font-weight: 500;
}

.grid_desc_1 > p {
  margin: 5px;
}

.zoom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.882);
  z-index: 9999;
}

.zoomed-img {
  max-width: 90%;
  max-height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.zoomed-img-container {
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 600px) {
  #grid_1 {
    display: grid;
    grid-template-columns: 90%;
    grid-gap: 20px;
    grid-auto-rows: minmax(240px, auto);
    width: 90%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: center;
  }

  #Marie-Cecile-4 {
    display: block;
    height: 220px;
    margin: auto;
  }
}
