.homeHeader {
  width: 25%;
  height: 100vh;
  background: linear-gradient(to left, #1f191b, #302829);
  position: fixed;
}

.logo {
  display: block;
  max-width: 100%;
  padding-top: 5%;
}

.barsIcon {
  display: none;
  color: grey;
}

.headerNav {
  width: 100%;
}

.headerNav > ul {
  width: fit-content;
  margin: auto;
  padding: 30px 0 0 0;
}

.headerNav > ul > li {
  list-style: none;
  padding: 20px 0 0 0;
  transition: transform 450ms ease;
}

.headerNav > ul > li:hover {
  transform: translate(15px, 0px);
}

.headerNav > ul > li > .link_nav {
  text-decoration: none;
  color: white;
  padding-left: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1em;
  border-left: solid #68bd97 2px;
}

.headerNav > ul > li:hover > .link_nav {
  color: #b6e0cd;
}

.photoNav {
  visibility: hidden;
  height: 0;
  width: 150px;
}

.photoNav > ul {
  width: fit-content;
  margin: auto;
}

.photoNav > ul > li {
  list-style: none;
  color: white;
  padding-top: 8px;
}

.photoNav > ul > li > .link_nav {
  text-decoration: none;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 0.9em;
}

#photos:hover {
  margin-bottom: 100px;
  transition: margin 0.5s ease-in-out;
}

#photos:not(:hover) {
  margin-bottom: 0;
  transition: margin 0.5s ease-in-out;
}

#photos:hover .photoNav {
  visibility: visible;
}

#photos:not(:hover) .photoNav {
  visibility: hidden;
}

#photos:hover .photoNav ul li:nth-child(1) .link_nav {
  animation-name: fade-in;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transition-delay: 0s;
  transform-origin: top;
}

#photos:hover .photoNav ul li:nth-child(2) .link_nav {
  animation-name: fade-in;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transition-delay: 0.15s;
  transform-origin: top;
}

#photos:hover .photoNav ul li:nth-child(3) .link_nav {
  animation-name: fade-in;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transition-delay: 0.3s;
  transform-origin: top;
}

@keyframes fade-in {
  from {
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

#photos:hover .photoNav ul li > .link_nav {
  opacity: 1;
}

#photos:not(:hover) .photoNav ul li:nth-child(1) .link_nav {
  animation-name: fade-out;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transition-delay: 0.3s;
  transform-origin: bottom;
}

#photos:not(:hover) .photoNav ul li:nth-child(2) .link_nav {
  animation-name: fade-out;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transition-delay: 0.15s;
  transform-origin: bottom;
}

#photos:not(:hover) .photoNav ul li:nth-child(3) .link_nav {
  animation-name: fade-out;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transition-delay: 0s;
  transform-origin: top;
}

@keyframes fade-out {
  from {
    transform: scaleY(1);
    opacity: 0;
  }
  to {
    transform: scaleY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 830px) {
  .homeHeader {
    width: 250px;
    height: auto;
    z-index: 1;
    background: linear-gradient(
      to left,
      rgba(31, 25, 27, 0.8),
      rgba(48, 40, 41, 0.8)
    );
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-250px);
    transition: transform 0.3s ease;
  }

  .menuOpen {
    transform: translate(0px);
  }

  .headerNav > ul > li > .link_nav {
    font-size: 1em;
  }

  .logo {
    display: none;
  }

  .barsIcon {
    display: block;
    padding-top: 10px;
    font-size: 2em;
    cursor: pointer;
  }

  .headerNav > ul {
    padding: 0 0 30px 0;
    margin-left: 20px;
  }

  .photoNav {
    width: auto;
  }

  .photoNav > ul > li > .link_nav {
    font-size: 1em;
    margin-left: -20px;
  }

  #photos:hover {
    margin-bottom: 90px;
  }
}
