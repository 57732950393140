#footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 70px;
  background-color: rgb(136, 57, 39);
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-size: 0.8rem;
  font-weight: 100;
  padding-bottom: 2px;
  margin-bottom: 0;
}
