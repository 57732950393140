/*grid for musicians*/

.first_img_block {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: auto;
}

.second_img_block {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: auto;
  margin-bottom: 50px;
  padding-top: 50px;
}

.third_img_block {
  display: flex;
  flex-direction: column;
  width: 27%;
}

.fourth_img_block {
  display: flex;
  flex-direction: column;
  width: 27%;
}

#rose {
  width: 45%;
}

.photo_block_2.first_photo {
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  position: relative;
}

#didier {
  width: 45%;
}

.photo_block_2.second_photo {
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  position: relative;
}

#michael {
  width: 37%;
}

.photo_block_2.third_photo {
  width: 100%;
  height: 0;
  padding-bottom: 130%;
  position: relative;
}

#fred,
#philippe,
#laurence {
  width: 100%;
}

#laurence {
  margin-top: -20%;
}

.photo_block_2.fourth_photo,
.photo_block_2.fifth_photo,
.photo_block_2.sixth_photo,
.photo_block_2.seventh_photo {
  width: 100%;
  height: 0;
  padding-bottom: 140%;
  position: relative;
}

.photo_block_2.first_photo img,
.photo_block_2.second_photo img,
.photo_block_2.third_photo img,
.photo_block_2.fourth_photo img,
.photo_block_2.fifth_photo img,
.photo_block_2.sixth_photo img,
.photo_block_2.seventh_photo img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

@media screen and (max-width: 650px) {
  .first_img_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: auto;
  }

  #rose {
    width: 90%;
    margin: auto;
  }
  #didier {
    width: 90%;
    margin: auto;
  }
  .second_img_block {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-bottom: 50px;
    padding-top: 0;
  }
  .third_img_block {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    justify-content: space-between;
  }
  #fred,
  #philippe {
    width: 45%;
  }
  .fourth_img_block {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    justify-content: space-between;
  }
  #laurence {
    margin-top: 0;
  }
  #laurence,
  #loic {
    width: 45%;
  }
  #michael {
    width: 70%;
    margin: auto;
  }
}
@media screen and (max-width: 430px) {
  .third_img_block {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
  }
  #fred,
  #philippe {
    width: 100%;
  }
  .fourth_img_block {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
  }
  #laurence,
  #loic {
    width: 100%;
  }
  #michael {
    width: 90%;
  }
}
