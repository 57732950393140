#date-home {
  width: 95%;
  height: auto;
  margin: auto;
  margin-bottom: 10%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-color: white;
  padding-bottom: 50px;
}

#date-home > h1 {
  width: 80%;
  margin: 20px auto;
  padding-top: 40px;
  font-size: 1.4em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: lighter;
  color: rgb(52, 52, 52);
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.2em;
}

.warning {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 94, 132);
  width: fit-content;
  height: fit-content;
  border-radius: 10px 0 0 0;
  font-size: 1em;
}

.date_confirm {
  margin: 0;
  padding: 5px;
}
.hour {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  width: fit-content;
  height: fit-content;
  align-items: center;
  border-radius: 10px 0 0 0;
  background-color: rgba(0, 255, 0, 0.496);
}
.text-hour {
  display: block;
  font-weight: bold;
  color: #343434;
  font-size: 1em;
  padding: 5px;
}
.first_year,
.second_year {
  width: 400px;
  height: 25px;
  margin: auto;
  color: white;
  background-color: rgb(52, 52, 52);
  text-align: center;
  padding-top: 2px;
  margin-top: 50px;
  margin-bottom: 10px;
  font-weight: bold;
  letter-spacing: 0.5em;
}

.come_soon {
  position: absolute;
  left: 5px;
  bottom: 0;
  height: 20px;
  width: 75px;
  font-size: 0.9em;
  background-color: rgb(191, 255, 0);
  text-align: center;
}
@media screen and (max-width: 830px) {
  #date-home {
    margin-top: 50px;
  }
}

@media screen and (max-width: 440px) {
  .first_year,
  .second_year {
    width: 100%;
  }
}
