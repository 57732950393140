@font-face {
  font-family: "Landasans";
  src: url("./fonts/Landasans-UltraLight.otf");
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.home {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.reading-part,
.contact-page {
  width: 75%;
  min-height: 100%;
  position: absolute;
  right: 0;
  padding-bottom: 70px;
  background-color: rgb(247, 245, 240);
}

.description {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: -10px;
  padding: 5%;
  background-color: white;
}

.description > .first-part > .first-line-part > h1 {
  font-family: "Landasans";
  text-transform: uppercase;
  text-align: right;
  letter-spacing: 5px;
  color: #394592;
  font-size: 2em;
  font-weight: 100;
  line-height: 1.4em;
}

.description > .first-part > .first-line-part > h2 {
  margin-top: 50px;
  font-family: "Landasans";
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: right;
  color: #68bd97;
  font-size: 1.5em;
  font-weight: 100;
  line-height: 1.2em;
}

.description .first-part {
  display: flex;
  justify-content: space-between;
}

.description > .first-part > main {
  width: 60%;
  text-align: justify;
}

.description > .first-part > main > h3 {
  font-weight: 100;
  text-align: center;
}

.description > .first-part > main > p {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.8rem;
  margin: 0;
}

.description > .first-part > main > :first-child {
  color: #6dc59e;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3em;
}

@media screen and (max-width: 830px) {
  .reading-part,
  .contact-page {
    width: 100%;
    min-height: 100%;
    margin: 0;
    position: absolute;
    right: 0;
    background-color: rgb(247, 245, 240);
  }

  .slider {
    margin-top: 50px;
  }
}

@media screen and (max-width: 700px) {
  .description .first-part {
    display: flex;
    flex-direction: column;
  }

  .description > .first-part > .first-line-part {
    display: flex;
    justify-content: space-between;
  }

  .description > .first-part > .first-line-part > h1 {
    width: 50%;
    text-align: left;
    margin: 0;
  }

  .description > .first-part > .first-line-part > h2 {
    width: 50%;
    margin: 0;
    font-family: "Landasans";
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: right;
    color: #68bd97;
    font-size: 1.5em;
    font-weight: 100;
    line-height: 1.2em;
  }

  .description > .first-part > main {
    width: 98%;
    text-align: justify;
    margin-top: 40px;
    margin-bottom: 20px;
    border-top: solid rgb(157, 157, 157) 1px;
  }
}
