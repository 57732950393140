#photo_contain {
  height: auto;
  margin: 0;
}

.photo_block {
  position: relative;
  flex-direction: column;
  width: 270px;
  height: 380px;
  margin: auto;
  overflow: hidden;
}

.gallery {
  height: 107%;
}
