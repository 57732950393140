.partner_title_block {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}

.partner_title {
  display: flex;
  width: 95%;
  height: 30vh;
  margin-top: 2%;
  background-color: white;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.partner_title > h3 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: auto;
  height: fit-content;
  font-weight: 100;
  font-size: 1.7rem;
  color: #68bd97;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0;
}

.partner_list {
  background-color: white;
  width: 95%;
  height: auto;
  margin-top: 3%;
}
.first_line_partner,
.second_line_partner {
  display: flex;
  height: auto;
  justify-content: space-between;
  margin: 3% 2% 0 2%;
}
#photo_barber {
  width: 22%;
}

#photo_artemuse {
  width: 40%;
}

#photo_cdc {
  width: 22%;
}

#photo_griiise {
  width: 22%;
}

#photo_nuit_blanche {
  width: 35%;
}

#photo_pollux {
  width: 22%;
}

#photo_choeur_et_concert {
  width: 22%;
  margin: 3% 0 3% 2%;
}

.partner_link {
  cursor: pointer;
}

@media screen and (max-width: 830px) {
  .partner_title {
    margin-top: 50px;
  }
}

@media screen and (max-width: 500px) {
  .first_line_partner,
  .second_line_partner {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
  }

  #photo_barber,
  #photo_artemuse,
  #photo_cdc,
  #photo_griiise,
  #photo_nuit_blanche,
  #photo_pollux {
    width: 75%;
    margin-top: 5%;
  }

  #photo_choeur_et_concert {
    display: block;
    width: 75%;
    margin: auto;
  }
}
