.contact-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-part {
  height: auto;
  background-color: rgba(0, 0, 0, 0.602);
  border-radius: 5px;
  color: white;
  padding: 8% 5%;
}

.contact-part > h3,
.contact-part > h4 {
  width: fit-content;
  margin: auto;
}

.contact-part > h3 {
  font-size: 2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 100;
}

.contact-part > h4 {
  font-size: 1.1rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 100;
  margin-top: 3%;
  margin-bottom: 7%;
}

.contact-part > p,
.contact-part > a {
  width: fit-content;
}

.contact-part > p {
  margin: 0 auto 0 25%;
}

#contact-name {
  font-size: 1.2rem;
}

.contact-part > a {
  display: block;
  color: white;
  margin: 0 auto 0 25%;
}

@media screen and (max-width: 830px) {
  .contact-background {
    width: 100%;
    position: absolute;
    left: 0;
  }
}

@media screen and (max-width: 510px) {
  .contact-part > p,
  .contact-part > a {
    margin: 0 auto 0 auto;
  }

  .contact-part > h4 {
    width: fit-content;
    margin: 3% 8% 7% 8%;
    text-align: center;
  }
}
