.carousel .thumbs {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 10px;
}

.carousel .thumb {
  margin: 0 5px;
  cursor: pointer;
}

.carousel-root {
  background-color: white;
}

.slider-container {
  position: relative;
  height: auto;
  min-height: 100px;
}

#load-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#load-spinner {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
