.calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 75px;
  height: 75px;
  background-color: rgb(52, 52, 52);
  padding-bottom: 3px;
}

.event {
  position: relative;
  display: flex;
  width: 400px;
  height: 100px;
  background: linear-gradient(white, rgb(246, 246, 246));
  border-bottom: solid rgb(199, 199, 199) 1px;
  margin-bottom: 10px;
  padding: 5px;
  margin: auto;
}

.date_box {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.day_name,
.month {
  font-size: 0.8em;
  color: white;
  line-height: 1em;
}

.day {
  font-size: 2.1em;
  font-weight: 600;
  color: white;
  line-height: 1em;
}

.event_desc_block {
  margin-left: 20px;
}

.city {
  font-size: 0.9em;
  height: fit-content;
  text-transform: uppercase;
  font-weight: bold;
  width: fit-content;
  margin-top: 6px;
  margin-bottom: 0;
}

.event_desc {
  width: fit-content;
  font-size: 1em;
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 0;
}

@media screen and (max-width: 440px) {
  .event {
    width: 100%;
  }
}
